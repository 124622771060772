.popup {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;

    &__layout {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        background-color: #cccccc;
        opacity: 0.5;
        width: 100vw;
        height: 100vh;
    }

    &__container {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 110;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
    }

    &__content {
        margin: 50px auto;
        padding: 10px;
        background-color: #ffffff;
        border: 1px solid #cccccc;
        border-radius: 2px;
        width: 700px;
        height: 700px;
    }
}

