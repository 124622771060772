.mag__blocks {
   display: grid;
   grid-template-columns: repeat(2, minmax(522px, 50%));
   grid-template-rows: 600px 200px 600px 600px 200px;
   grid-gap: 20px;
   grid-auto-flow: row;

  .mag-block {

      min-height: 590px;
      height: 100%;

      background-color: #0500FF10;

      &:nth-child(3n+1) {
         grid-row-end: span 2;
         grid-column-end: span 2;
      }
  }

}

@media screen and (max-width: 500px) {
   .mag__blocks{
      grid-template-columns: repeat(1, minmax(100%, 50%));
      grid-template-rows: repeat(auto-fit, 600px);
      justify-content: center;
      
      .mag-block {
         &:nth-child(3n+1) {
            grid-row-end: span 1;
            grid-column-end: span 1;
         }
     }
   }

}
