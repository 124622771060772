@import '../../styles/colors.scss';

.list-blocks {
   width: 100%;
}

.list-page {

   .list_block__search-view-page {
      display: flex;
      justify-content: center;
      flex-direction: column;
      
      width: 100%;
      max-width: 1077px;

      text-align: center;

      padding: 50px;

      h3 {
         font-size: 60px;
         text-transform: uppercase;
      }

      .whos-descr {
         font-family: Playfair Display;
         font-size: 14px;

         padding-top: 20px;
         padding-bottom: 30px;
      }
   }

   .search-descr {
      font-family: Playfair Display;
      font-style: normal;
      font-weight: normal;
      font-size: 26px;
      line-height: 28px;

      padding: 50px 10px;
   }
   
   .list__blocks {
      /* grid-gap: 146px 20px; */      
      /* grid-auto-flow: row; */
      /* grid-template-columns: repeat(3,minmax(522px,50%)); */
      /* grid-template-rows: 670px 570px 670px; */

      display: grid;
    
      grid-gap: 40px 50px;
      grid-template-columns: repeat(auto-fit,380px);
      width: 100%;
      justify-content: center;

      .logo-wrap {
         display: flex;
         flex-direction: row;
         justify-content: center;

         height: 70px;

         margin-bottom: 9px;

         .logo {
            width: 70px;
            height: 70px;
            left: 686px;

            background: #C4C4C4;
            border: 1px solid #000000;
            border-radius: 35px;
            box-sizing: border-box;

            overflow: hidden;
         }
      }

      .image-footer {

         text-align: center;

         .image-subtitle {
            font-family: Playfair Display;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 28px;

            letter-spacing: 0.08em;

            text-transform: uppercase;
         }

         .info {
            font-family: Playfair Display;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 15px;

            letter-spacing: 0.08em;

            span {
               text-transform: uppercase;
            }
         }

      }
   }

   h1 {
      color: $list-color;
   }
}