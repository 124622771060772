@import "../../styles/colors.scss";
.list_block {

   &__search {
      display: flex;
      justify-content: center;
      flex-direction: column;
      
      width: 100%;
      max-width: 1077px;

      text-align: center;

      border: 1px solid $list-color; //NOTE: FOR HOMEPAGE
      padding: 80px 50px; //NOTE: FOR HOMEPAGE

      h3 {
         font-size: 60px;
         text-transform: uppercase;
      }

      .whos-descr {
         font-family: Playfair Display;
         font-size: 14px;

         padding-top: 20px;
         padding-bottom: 30px;
      }
   }
}   

@media screen and (max-width: 500px) {
   .list_block {
      .list_block__search {
        .list_block_title_who {
           font-size: 20px;
        }

        .list_block_subtitle_who {
           font-size: 10px;
        }

        .list__form {
           form {
              div[class*="formFilters"] {
               row-gap: 20px;
               margin-top: 25px;
              }
           }
        }
      }
   }
}