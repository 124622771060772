.footer {
   width: 100%;
   padding: 5px 0;

   text-align: center;

   &__nav {
      font-size: 15px;
      margin-bottom: 15px;
   }

   &__copyright {
      font-size: 17px;
   }
}