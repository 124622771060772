.image-card {
   display: flex;
   flex-direction: column;
   background-size: cover;
   background-position: center;

   text-align: center;
   font-size: 17px;

   .image-header {
      text-align: center;
      // background-color: whitesmoke;

      margin-bottom: 10px;

      .title {
         font-size: 36px;
         line-height: 1;
      }

      .subtitle {
         font-size: 18px;
         line-height: 1;
      }

      .date {
         font-size: 20px;
      }
   }

   img {
      width: 100%;
      height: 500px;
      object-fit: cover;
      background-color: rgb(163, 163, 163);
   }

   .image-footer {
      // background-color: whitesmoke;
      margin-top: 10px;
   }
}
