.featured-section {
    margin-top: 20px;

    .featured {
        color: #B9F100;
        font-size: 60px !important;
    }
}

@media screen and (max-width: 500px){
    .featured-section {
        margin-top: 10px;

        .featured {
            font-size: 28px !important;
        }

        .image-footer {
            font-size: 13px;
        }       
    }
}