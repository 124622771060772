.club__blocks {
   width: 100%;
   display: flex;
   justify-content: space-around;
   margin-top: 40px;

   .club-media {
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;

       .club-block {
           width: 500px;
        }

       .logo-wrap {
         margin-bottom: 10px;
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
       
         .logo {
             width: 100px;
             height: 100px;
             border: 1px solid #000000;
             border-radius: 100px;
             background: #C4C4C4;
             overflow: hidden;
         }

         .club-media-title {
             font-size: 40px;
             color: #06DFED;
         }

         .club-member {
             font-size: 20px;
         }

         .club-location {
             font-size: 14px;
         }
       }
   }
}


.club-header {
    font-size: 20px;
    text-align: center;
    margin-top: -5px;
}

@media screen and (max-width: 500px) {
    .club__blocks {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 40px;
     
        .club-block {
          margin-bottom: 20px;
        }
     }

     .club-header {
        border: 1px solid #06DFED;
        font-size: 15px;
        padding: 15px;
    }
}