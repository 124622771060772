.awards-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;

  &__logo {
     width: 100%;
     display: flex;
     justify-content: space-around;
     
     img {
        width: 100px;
     }
  }
}

.awards__blocks {
   display: grid;
   grid-template-columns: repeat(auto-fit,522px);
   justify-content: center;
   grid-auto-flow: row;
   grid-gap: 20px;

   width: 100%;

  .awards-block {

      min-height: 650px;

      color: white;
      text-align: center;
      font-size: 17px;

      background-color: #5D00A710;

  }

}

@media screen and (max-width: 500px) {
   .awards {
      font-size: 40px !important;
   }
   
   .awards__blocks {
      grid-template-columns: repeat(auto-fit, 100%);
   }

   .awards-info {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      padding: 50px;

      &__text {
         width: 100%;
      }

      &__logo {
         width: 100%;
         margin-bottom: 30px;
         margin-right: 40px;
      }
   }
}