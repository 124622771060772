@import '../../styles/colors.scss';


.mag-page {

   h1 {
      color: $mag-color;
   }

   .mag-menu{
      width: 100%;
      display: flex;
      justify-content: space-around;
      font-size: 26px;
   }

   .mag-menu > div {
      cursor: pointer;
   }

   .mag-menu-filters-container {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 30px;
   }

   .mag-menu-filters-container.hide {
      display: none;
   }

   .mag-content-section {
      width: 100%;
   }

   .mag-media.main {
     display: grid;
     grid-template-columns: repeat(4, minmax(300px, 50%));
     grid-template-rows: repeat(6, auto);
     justify-content: center;
     align-items: center;
     margin-top: 40px;
     gap: 50px 50px;

     div {
        .image-block {
           background-size: contain;
        }
     }
   }

   .span-grid {
      grid-row-end: span 2;
      grid-column-end: span 2;
   }

   .mag-media.filtered {
      display: grid;
      grid-template-columns: repeat(4, minmax(400px, 50%));
      grid-template-rows: auto;
      justify-content: center;
      align-items: center;
      gap: 100px 20px;
      margin-top: 90px;

      .span-grid {
         grid-row-end: span 1;
         grid-column-end: span 1;
      }
   }

}