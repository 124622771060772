@import './colors.scss';

* {
   box-sizing: border-box;
}
html {
   display: flex;
   justify-content: center;
}

body {
   width: 100%;
   max-width: 1440px;
   padding: 25px;
   margin: 0;
   font-family: 'Playfair Display', Times, serif;

   box-sizing: border-box;
}


h1, h2, h3, h4, h5, h6 {
   display: block;
   font-style: normal;
   font-weight: normal;
   text-align: center;

   margin: 0;
}

h1 {
   text-transform: uppercase;
   font-size: 85px;
   line-height: 69px;
   letter-spacing: 0.25em;
}

h2 {
   font-size: 60px;
   line-height: 60px;
   letter-spacing: 0.22em;   
}

a {
   text-decoration: none;
   color: $black;
}

img {
    object-fit: contain;
    width: 100%;
}

.bank {
   color: $bank-color;
}
.list {
   color: $list-color;
}
.mag {
   color: $mag-color;
}
.awards {
   color: $awards-color;
}
.club {
   color: $club-color;
}

.txt-center {
   text-align: center;
}

section {
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100%;
   max-width: 1240px;
   margin: 0 auto 25px auto;

   .heading {
      margin-bottom: 20px;
      margin-top: 47px;
   }

   .subtitle {
      font-size: 18px;
      text-align: center;
      text-transform: uppercase;
   
      margin: 15px 0 27px 0;
   
   }
}

.main {
   h1, h2 {
      font-size: 85px;
      text-transform: uppercase;
      text-align: center;
      line-height: 69px;
      letter-spacing: 0.25em;
   }

   section {
      max-width: 1065px;
   }
}