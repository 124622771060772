@import '../../styles/colors.scss';

.awards-page {

   section {
      max-width: unset;
   }

   h1 {
      color: $awards-color;
   }

   .alt-description, .description {
      font-size: 20px;
   }

   .description {
      max-width: 700px;
      text-align: center;
      margin: 0 auto;
   }

   .alt-menu {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      text-transform: uppercase;
      text-align: center;

      &__item {
         letter-spacing: 0.15em;
         font-size: 48px;
         margin: 0 20px;
      }
   }

   .awards-logo-list {
      display: grid;
      grid-template-columns: repeat(auto-fit, 270px);
      grid-gap: 80px;
      width: 100%;
      justify-content: center;
      margin: 20px 0 70px 0;

      img {
         max-height: 100px;
      }
   }

   .alt-description {
      max-width: 1000px;
      text-align: center;
      margin: 40px auto;
   }
   
   .awards-grid {
      grid-gap: 20px;
      grid-template-columns: repeat(auto-fit,332px);

      .image-card {
         img {
            width: 332px;
            height: 332px;
         }
      }
   }
}