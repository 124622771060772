@import "../../styles/colors.scss";

list-blocks {
  width: 100%;
}

.align-center {
  text-align: center;
}

.bank-fiter {
  &__creative-categories {
    color: #000;
    text-align: center;
  }
}

.bank-filter__creative-categories {
  display: flex;
  justify-content: center;
}

.list-blocks section h2 {
  visibility: hidden;
}

.bank-page {
  .heading {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .heading h2 {
    color: black;
  }

  .subtitle {
    margin-top: 30px;
    color: black;
  }

  .bank_block__search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 60px 50px;
    width: 100%;
    padding-top: 20px;

    .list__form form {
      align-items: flex-end;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .list__form {
      display: grid;
      grid-template-columns: repeat(4, auto);
      gap: 20px 60px;
    }

    .label {
      text-transform: uppercase;
      margin-bottom: 20px;
      color: black;
    }

    @media screen and (max-width: 900px) {
      .list__form {
        grid-template-columns: repeat(2, auto);
        justify-content: center;
        gap: 20px 60px;
      }
    }

    @media screen and (max-width: 500px) {
      .list__form {
        gap: 20px 15px;
      }

      .label + div > div {
        width: 150px; //Changes size of field
      }

      .label + div > div div:last-child > div {
        left: 115px; //Moves arrow
      }
    }

    @media screen and (max-width: 320px) {
      .list__form {
        gap: 20px 15px;
      }

      .label + div > div {
        width: 120px; //Changes size of field
      }

      .label + div > div div:last-child > div {
        left: 85px; //Moves arrow
      }
    }
  }

  h1 {
    color: $bank-color;
  }
}

.bank__blocks-view {
  display: grid;
  grid-template-columns: repeat(3, minmax(400px, 50%));
  justify-content: center;
  align-items: center;
  gap: 100px 20px;
  margin-top: 90px;

  .image-block {
    position: relative;
  }
}

.image-footer {
  display: flex;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
}

.image-content {
  text-align: center;
  color: black;
}

.play-btn-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  pointer-events: none
}

.media-bank-popup__close-btn-container {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 10px;
  top: 21px;
  width: 100%;
}

.media-bank-popup__header {
  position: relative;
}

.popup-image-container {
  width: 80%;
  margin: 0px auto;
  margin-top: 30px;
}

.popup-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.popup-footer-name {
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 10px;
  cursor: pointer;
}

.popup-footer-agency {
  cursor: pointer;
}

.popup-footer-name:hover {
  color: #b9f100;
}

.popup-footer-agency:hover {
  color: #b9f100;
}

@media screen and (max-width: 1270px) {
  .bank__blocks-view {
    grid-template-columns: repeat(2, minmax(400px, 50%));
  }
}

@media screen and (max-width: 768px) {
  .bank__blocks-view {
    grid-template-columns: repeat(1, 500px);
    grid-template-rows: repeat(auto-fill, 550px);
    gap: 30px 0px;
  }

  .bank__blocks-view > div {
    margin-bottom: 60px;
  }

  .bank-page {
    .bank_block__search {
      width: 700px;
    }
  }
}

@media screen and (max-width: 500px) {
  .bank-page .heading {
    margin-top: 10px;
  }

  .bank__blocks-view {
    grid-template-columns: repeat(1, 320px);
    grid-template-rows: repeat(auto-fill, 550px);
    gap: 30px 0px;
    margin-top: 10px;
  }

  .bank__blocks-view > div {
    margin-bottom: 60px;
  }

  .bank-page {
    .bank_block__search {
      width: 350px;
    }

    .subtitle {
      margin-top: 60px;
    }
  }

  .bank-filter__creative-categories {
    margin-bottom: 20px;
  }

  .bank-filter__creative-categories > label > div > div div:last-child > div {
    left: 170px; //Moves arrow
  }

  .bank-filter__creative-categories > label > div > div {
    width: 170px; //Changes size of field
  }

  .media-bank-popup__close-btn-container {
    right: 0px;
  }
}

@media screen and (max-width: 320px) {
  .bank__blocks-view {
    grid-template-columns: repeat(1, 280px);
  }

  .bank-page {
    .bank_block__search {
      width: 320px;
    }

    .heading {
      h1 {
        font-size: 60px;
      }

      h2 {
        font-size: 40px;
      }
    }
  }
}
