.slider {
   .image-block {
      height: 751px !important;
   }
}

.image-block {
  width: 100%;
  height: 500px;

   /* display: flex; */
   /* flex-direction: column; */
   /* justify-content: space-between; */
   /* background-size: cover; */
   background-position: center;
   background-repeat: no-repeat;
   /* background-color: whitesmoke; */

   /* height: 100%; */

   box-sizing: border-box;

   color: white;
   text-align: center;
   font-size: 17px;


   a {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;

      height: 100%;
      width: 100%;
      padding: 30px;
   }

   .image-header, .image-footer {
      color: white;
   }

   .image-header {
      text-align: center;
      text-transform: uppercase;
      color: white;

      h3 {
         font-size: 42px;
         line-height: 1;
         margin-bottom: 10px;
      }

      .image-subtitle {
         font-size: 20px;
         line-height: 28px;
      }

      .info {
         font-size: 20px;
      }
   }
}
