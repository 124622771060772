@import "../../styles/colors.scss";

.header {
   display: flex;
   align-items: flex-start;
   margin-top: 20px;
   
   &__logo {
      width: 100%;
      max-width: 180px;

      margin-top: -30px;
      flex-grow: 1;

      img {
         width: 100%;
         object-fit: contain;
      }
   }

   &__navbar {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 2;

      text-align: center;

      .signature {
         font-size: 15px;
         line-height: 20px;
         letter-spacing: 1.5em;

         margin-bottom: 5px;
         margin-right: -1.5em;
      }

      .stats {
         display: none;
         // font-size: 12px;
         // line-height: 16px;
         // letter-spacing: 0.25em;
      }

      .menu {
         display: flex;
         justify-content: center;
         flex-wrap: wrap;

         text-transform: uppercase;
         text-align: center;

         margin-top: 0;
         margin-bottom: 15px;

         &__item {
            font-size: 36px;
            letter-spacing: 0.075em;

            margin: 0 5px;
         }

      }

   }

   &__login {
      display: flex;
      justify-content: flex-end;
      flex-grow: 1;

      text-transform: uppercase;
      font-size: 18px;
      line-height: 18px;

      img {
         width: 18px;
         height: 18px;

         margin-left: 15px;
      }
   }

}

@media screen and (max-width: 500px){
   .header{
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;

      &__logo{
         max-width: 85px;
      }
   }

   .menu-open{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      font-size: 35px;
   }

   .open-nav-icons{
      display: flex; 
      justify-content: space-around;
      width: 100%;
   }

   .seach-icon{
      transform: scaleX(-1);
      -moz-transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      -ms-transform: scaleX(-1);
   }
}

.nav-btn{
   margin-right: 20px;
}

.open-nav-btn-container{
   margin-bottom: 10px;
}

@media screen and (max-width: 320px){
   .nav-btn{
      margin-right: 5px;
   }
}