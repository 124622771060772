@import '../../styles/colors.scss';

.profile-page {
    .gallery-blocks {
        grid-gap: 40px 50px;
        display: grid;
        grid-template-columns: repeat(auto-fit,380px);
        justify-content: center;
        width: 100%;
    }

    .profile-tab-blocks {
        width: 100%;
        grid-gap: 40px 50px;
        display: grid;
    }

    &__wrap {
        grid-gap: 40px 50px;
        display: grid;
        /* grid-template-columns: repeat(auto-fit,380px); */
        grid-template-columns: repeat(auto-fit, 100%);
        object-fit: cover;
        justify-content: center;
        /* justify-content: right; */
        width: 100%;

        .video-block {
            object-fit: cover;
        }

        .carousel {
            .thumbs-wrapper {
                margin: 0 !important;
            }
        }

        .carousel-slider  {
            position: relative;

            .control-arrow {
                top: 50% !important;
                height: 40px;
            }

            .carousel-status {
                display: none;
            }

            .carousel-arrow-btn {
                cursor: pointer;
                display: inline-flex;
                align-items: center;
                height: 0;
                z-index: 5;
            }

            .control-arrow-next {
                position: absolute;
                right: 200px;
                top: 50%;
            }

            .control-arrow-prev {
                position: absolute;
                top: 50%;
                left: 200px;
            }
        }

        .slider {
            text-align: center;

            &__categories {
                padding-top: 8px;

                span {
                    margin: 0 20px;
                    font-size: 18px;
                    line-height: 15px;

                    text-align: center;
                    letter-spacing: 0.04em;

                    color: #E400B2;

                    text-transform: uppercase;
                }
            }
        }

        .contacts {
            display: grid;
            grid-template-columns: repeat(auto-fit,405px);
            justify-content: center;

            font-size: 20px;
            letter-spacing: .08em;
            line-height: 28px;

            text-align: center;
            letter-spacing: 0.08em;
            font-size: 20px;
            line-height: 28px;

            .col {
                padding: 20px;
            }

            &__title {
                font-size: 30px;
                line-height: 30px;

                text-align: center;
                letter-spacing: 0.08em;

                color: #000000;
            }

            &__location {
                text-transform: uppercase;
                color: #E400B2;
            }

            a {
                color: #E400B2;
            }
        }
    }

    @media (min-width: 1024px) {
        .portfolio__form {
            display: grid;
            grid-gap: 20px;
            grid-auto-flow: column;
        }

        .roster__form {
            display: grid;
            grid-gap: 20px;
            grid-auto-flow: column;
        }
    }

    .portfolio__form {
        margin-top: 10px;
        margin-bottom: 30px;
    }

    .roster__form {
        margin-bottom: 30px;
        margin-top: 10px;
    }

    .instagram-preview {
        font-family: Playfair Display;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.08em;
        color: #000000;

        margin-top: 45px;

        &__logo {
            display: inline-block;
            border-radius: 70px;
            width: 108px;
            height: 108px;

            background-position-y: center;
            background-size: cover;
            border: 1px solid #000;
        }

        &__descr {
            margin-top: 14px;

            .read-more-button {
                font-size: 18px;
                color: #E400B2;
                cursor: pointer;
            }
        }
    }

    .profile-block {
        background-color: #f5f5f5;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        color: #fff;
        font-size: 17px;
        height: 500px;
        text-align: center;
    }

    .heading {
        font-family: Playfair Display;
        font-style: normal;
        font-weight: normal;
        color: #000000;
        text-align: center;
        text-transform: uppercase;
    }

    h2 {
        font-size: 48px;
        line-height: 30px;

        letter-spacing: 0.15em;
    }

    .subtitle-type {
        font-size: 26px;
        line-height: 60px;

        letter-spacing: 0.1em;
    }

    .subtitle-category {
        font-size: 14px;
        line-height: 28px;

        letter-spacing: 0.08em;
    }

    .image-footer {
        text-align: center;
        text-transform: uppercase;

        .image-subtitle {
            font-family: Playfair Display;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: .08em;
            line-height: 28px;
            text-transform: uppercase;
        }

        .info {
            font-family: Playfair Display;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: .08em;
            line-height: 15px;
        }
    }
}

@media screen and (max-width: 500px){
    .profile-page {
        .gallery-blocks {
            grid-template-columns: repeat(auto-fit, 100%);
        }

        &__wrap {
            .carousel-slider  {
                .control-arrow-next {
                    position: absolute;
                    right: 0;
                    top: 50%;
                }
    
                .control-arrow-prev {
                    position: absolute;
                    top: 50%;
                    left: 0;
                }
            }

            .slider {
                &__categories {
                    span {
                        font-size: 10px;
                    }
                }
            }
        }
    }
}