.content{
    height: 600px;
    display: flex;
    align-items: center;
}

.loader{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-icon{
    animation: spin 1s linear infinite forwards;
}

@keyframes spin {
    from{
      transform: rotate(0deg)
    }
    
    to{
        transform: rotate(360deg)
    }
}