.news-sign-up-container {
    margin-top: 50px;
    margin-bottom: 50px;

    .news-logo {
        width: 200px;
        margin-bottom: 50px;
    }

    .news-sign-up-form {
        width: 100%;
        border: 1px solid #06DFED;
        padding: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .news-sign-up-text-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 28px;
            letter-spacing: 0.08em;

            .news-sign-up-title {
                font-size: 42px;
                margin-top: 0px;
                margin-bottom: -15px;
                letter-spacing: 0.1em;
            }
        }

        .news-sign-up-input-container {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 20px;

            input {
                margin-right: 40px;
                width: 272px;
                height: 30px;
                text-align: center;
                border: solid 1px #06DFED;
            }

            button {
                width: 258px;
                height: 30px;
                font-size: 13px;
                text-align: center;
                background-color: #06DFED;
                border: none;
                cursor: pointer;
                display: flex;
                align-items: center; 
                justify-content: center;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .news-sign-up-container {
        margin-top: 30px;

        .news-logo {
            width: 150px;
            margin-bottom: 30px;
        }

        .news-sign-up-form {
            padding: 30px;

            .news-sign-up-text-container {
                text-align: center;
                font-size: 11px;

                .news-sign-up-title {
                    font-size: 20px;
                }
            }

            .news-sign-up-input-container {
                flex-direction: column;
                align-items: center;
    
                input {
                    margin-right: 0px;
                    width: 183px;
                    margin-bottom: 20px;
                    height: 20px;
                }

                button {
                    width: 183px;
                    font-size: 10px;
                    height: 20px;
                }
            }
        }

    }
}