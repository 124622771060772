@import '../../styles/colors.scss';

.club-page {
   margin-top: 60px;      

   h1 {
      color: $club-color;
   }

   .club-tabs {
      margin-top: 30px;
   }

   .club-content{
      margin-top: 20px;

      .club-about-container {
         display: flex;
         flex-direction: column;
         align-items: center;
         width: 100%;

        .club-about-center{
           text-align: center;
           font-size: 20px;
           width: 60%;
        }

        .club-about{
           font-size: 20px;
        }
     }

     .club-members-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        font-size: 20px;

        .club-members {
         text-align: center;
         width: 60%;
      }
     }
   }
   
}