.bank__blocks {
   display: grid;
   grid-template-columns: repeat(2, minmax(522px, 50%));
   grid-template-rows: repeat(3, 500px);
   justify-content: center;
   width: 100%;
   grid-gap: 10px;

   & > div:nth-child(3) {
      grid-column-end: span 2;
   }
}

@media screen and (max-width: 500px) {
   .bank__blocks {
      grid-template-columns: repeat(auto-fit, 100%);
   
      & > div:nth-child(3) {
         grid-column-end: span 1;
      }
   }
}
